import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import CTA from '../../components/global/cta';
import GetInTouch from '../../components/global/get-in-touch';
import { buildImageObj } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';
import AnimatedImage from '../global/animated-image';
import AnimatedText from '../global/animated-text';
import AnimatedVideo from '../global/animated-video';
import Container from '../global/container';
import Button from '../global/elements/buttons/button';

function Work(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.25
  });

  const { _rawIntro, title, subtitle, headerMedia, introMedia, _rawContent } = props;
  return (
    <Container>
      <article className="work">
        <div className={`hero hero--work bg-white ${inView ? 'in-view' : ''}`} ref={ref}>
          {headerMedia.image && headerMedia.image.asset && (
            <img
              src={imageUrlFor(buildImageObj(headerMedia.image))
                .url()}
              alt={headerMedia.alt}
            />
          )}

          {headerMedia.video && headerMedia.video.asset && (
            <video className="hero__media" autoPlay loop muted playsInline>
              <source src={headerMedia.video.asset.url} type="video/mp4" />
            </video>
          )}

          <div className="site-wide">
            <div className="work--details-banner">
              <div className="col copy">
                <h1>{title}</h1>
                <h2>{subtitle}</h2>
                {_rawIntro && <AnimatedText blocks={_rawIntro} />}
                {introMedia.image && introMedia.image.asset && (
                  <img
                    className="hero__small-image"
                    src={imageUrlFor(buildImageObj(introMedia.image))
                      .fit('crop')
                      .auto('format')
                      .url()}
                    alt={introMedia.alt}
                  />
                )}
              </div>
            </div>
          </div>
          <GetInTouch bottom />
        </div>

        <div className="work__details">
          {_rawContent &&
            _rawContent.map((rC, index) => {
              if (rC.layoutType === 'single') {
                return (
                  <div className="work__details-section" key={index}>
                    <div className="site-wide">
                      <div className="work__details-copy">
                        {rC.column1 &&
                          rC.column1.map((c1, index) => <AnimatedText blocks={c1} key={index} />)}
                      </div>
                    </div>
                  </div>
                );
              } else if (rC.layoutType === 'media') {
                return (
                  <div className="work__details-section work__details-section--big" key={index}>
                    <div className="site-wide">
                      <div className="work__details-media">
                        {rC.column1 &&
                          rC.column1.map((c1, index) => {
                            if (c1.video) {
                              return <AnimatedVideo key={index} src={c1.video.asset.url} />;
                            } else if (c1.image) {
                              return (
                                <AnimatedImage
                                  key={index}
                                  src={imageUrlFor(buildImageObj(c1.image))}
                                  alt={c1.alt}
                                />
                              );
                            }
                          })}
                      </div>
                    </div>
                  </div>
                );
              } else if (rC.layoutType === 'left') {
                return (
                  <div className="work__details-section work__details-section--split" key={index}>
                    <div className="site-wide grid-container grid-container--4-2 grid-container--reverse">
                      <div className="grid-column work__details-image">
                        {rC.column1 &&
                          rC.column1.map((c1, index) => <AnimatedText blocks={c1} key={index} />)}
                      </div>
                      <div className="grid-column work__details-copy">
                        {rC.column2 &&
                          rC.column2.map((c2, index) => <AnimatedText blocks={c2} key={index} />)}
                        {rC.buttonCopy && <Button text={rC.buttonCopy} link={rC.button} target={rC.buttonTarget} />}
                      </div>
                    </div>
                  </div>
                );
              } else if (rC.layoutType === 'right') {
                return (
                  <div className="work__details-section work__details-section--split" key={index}>
                    <div className="site-wide grid-container grid-container--4-2">
                      <div className="grid-column work__details-copy">
                        {rC.column2 &&
                          rC.column2.map((c2, index) => <AnimatedText blocks={c2} key={index} />)}
                        {rC.buttonCopy && <Button text={rC.buttonCopy} link={rC.button} />}
                      </div>
                      <div className="grid-column work__details-image">
                        {rC.column1 &&
                          rC.column1.map((c1, index) => <AnimatedText blocks={c1} key={index} />)}
                      </div>
                    </div>
                  </div>
                );
              } else if (rC.layoutType === 'quote') {
                return (
                  <div
                    className="work__details-section work__details-section--split work__details-section--quote"
                    key={index}
                  >
                    <div className="site-wide grid-container grid-container--half">
                      <div className="grid-column work__details-image">
                        {rC.stat &&
                        <div className='work--stat'>
                          {rC.stat.map((stat, index) => <AnimatedText blocks={stat} key={index} />)}
                        </div>}
                        {rC.column1 &&
                          rC.column1.map((c1, index) => <AnimatedText blocks={c1} key={index} />)}
                      </div>
                      <div className="grid-column work__details-copy">
                        {rC.column2 &&
                          rC.column2.map((c2, index) => <AnimatedText blocks={c2} key={index} />)}
                        {rC.buttonCopy && <Button text={rC.buttonCopy} link={rC.button} />}
                      </div>
                    </div>
                  </div>
                );
              } else if (rC.layoutType === 'testimonial') {
                return (
                  <div
                    className="work__details-section work__details-section--split work__details-section--testimonial"
                    key={index}
                  >
                    <div className="site-wide">
                      <div className={`testimonial-container ${inView ? 'in-view' : ''}`} ref={ref}>
                        {rC.column1 &&
                            rC.column1.map((c1, index) => <AnimatedText blocks={c1} key={index} />)}
                        {rC.column2 &&
                            rC.column2.map((c2, index) => <AnimatedText blocks={c2} key={index} />)}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>

        <div className="site-wide">
          <div className="grid-container grid-container--4">
            <div className="grid-column">
              <CTA inline />
            </div>
          </div>
        </div>
      </article>
    </Container>
  );
}

Work.propTypes = {
  _rawIntro: PropTypes.array,
  _rawContent: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  headerMedia: PropTypes.object,
  introMedia: PropTypes.object
};

export default Work;
